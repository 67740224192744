<template>
  <Layout>
    <div class="container-fluid pt-4">
      <div class="row justify-content-around">
        <div class="col">
          <h5 class="page-header">Manage reminders</h5>
        </div>
        <div class="col text-end">
          <BaseButton v-if="user.isAdminUser" :classes="'btn-success'" @click="createNewReminder()">
            New Reminder
          </BaseButton>
        </div>
      </div>
      <hr class="dotted-spacer" />
      <div class="row pt-4">
        <div class="col">
          <!-- Loading spinner -->
          <div
            v-if="$apollo.loading"
            class="position-fixed end-0 w-100 justify-content-center"
            style="background: rgba(255, 255, 255, 0.8); height: 100vh; z-index: 1050"
          >
            <div class="text-center col pt-5 mt-5">
              <BaseSpinner />
              <p class="d-block lead fs-4 mt-5">Loading reminders</p>
            </div>
          </div>
          <div v-else>
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :sort-options="{ enabled: true }"
              :pagination-options="paginationOptions"
              style-class="vgt-table condensed striped"
              @on-row-click="tableOnEditItem"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'blocking'">
                  <BaseIcon v-if="props.row.blocking === true" class="text-primary text-lg" :name="'check'" />
                  <BaseIcon v-else :name="'times'" class="text-muted text-lg" />
                </span>

                <!-- Custom Actions section -->
                <div
                  v-else-if="props.column.field == 'after'"
                  class="capitalize-first text-xl w-100 flex text-end"
                  :title="props.row.id"
                >
                  <BaseButton
                    v-if="user.isAdminUser"
                    class="inline text-primary cursor-pointer mr-3"
                    :classes="'px-2'"
                    @click="tableOnEditItem"
                  >
                    <BaseIcon :name="['far', 'edit']" />
                  </BaseButton>
                  <!-- <BaseButton
                    v-if="user.isAdminUser"
                    class="inline text-primary cursor-pointer mr-3"
                    :classes="'px-2'"
                    title="Delete reminder"
                    @click="deleteReminder(props.row.id)"
                  >
                    <BaseIcon :name="['far', 'trash-alt']" />
                  </BaseButton> -->
                </div>

                <!-- standard column -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@layouts/main";
import { LocalGetSelf } from "@gql/user";
import { GetRemindersForCompany } from "@gql/reminder";

import { format, parseISO } from "date-fns";

export default {
  page: {
    title: "Users",
    meta: [{ name: "description", content: "Users" }],
  },
  components: { Layout },
  data() {
    return {
      loading: true,
      showHidden: false,
      columns: [
        {
          label: "Start date",
          field: "startDateFormatted",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "do MMM yyyy",
        },
        {
          label: "End date",
          field: "endDateFormatted",
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "do MMM yyyy",
        },
        {
          label: "Reminder Text",
          field: "text",
          filterOptions: {
            // styleClass: '', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            // placeholder: "Tag name", // placeholder for filter input
            // filterValue: 'Test', // initial populated value for this filter
            // filterDropdownItems: [], // dropdown (with selected values) instead of text input
            // filterFn: this.columnFilterFn, // custom filter function that takes two vars: data, filterString: should return true if data matches the filterString, otherwise false
            // trigger: 'enter', // only trigger on enter not on keyup
          },
        },
        {
          label: "Blocking",
          field: "blocking",
          type: "boolean",
        },
        {
          label: "Created By",
          field: "createdByName",
          filterOptions: {
            // styleClass: '', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            // placeholder: "Tag name", // placeholder for filter input
            // filterValue: 'Test', // initial populated value for this filter
            // filterDropdownItems: [], // dropdown (with selected values) instead of text input
            // filterFn: this.columnFilterFn, // custom filter function that takes two vars: data, filterString: should return true if data matches the filterString, otherwise false
            // trigger: 'enter', // only trigger on enter not on keyup
          },
        },
        {
          label: "",
          field: "after",
        },
      ],
      paginationOptions: {
        enabled: true,
        perPage: 25,
        perPageDropdown: [10, 25, 50, 100],
      },
    };
  },
  apollo: {
    user: LocalGetSelf,
    reminders: {
      query: GetRemindersForCompany,
      variables() {
        return {
          companyId: this.user.company.id,
        };
      },
      skip() {
        return !this.user;
      },
      error: (err) => this.$log.error(err),
      fetchPolicy: "cache-and-network",
    },
  },
  computed: {
    rows: function () {
      if (!this.reminders) return [];
      return this.reminders.map((reminder) => ({
        ...reminder,
        startDateFormatted: format(parseISO(reminder.startDate), "yyyy-MM-dd"),
        endDateFormatted: format(parseISO(reminder.endDate), "yyyy-MM-dd"),
        createdByName: reminder.createdBy.name,
      }));
    },
  },
  methods: {
    refreshTable: function () {
      this.loading = true;
      this.$apollo.queries.users.refresh();
    },
    tableOnEditItem: function (props) {
      const reminderId = props.row.id;
      if (this.user && this.user.isAdminUser) {
        this.$log.debug("tableOnEditItem - going to reminders.edit", { id: reminderId });
        this.$router.push({ name: "reminders.edit", params: { id: reminderId } });
      }
    },
    createNewReminder: function () {
      if (this.user && this.user.isAdminUser) {
        this.$log.debug("createNewReminder - going to reminders.new");
        this.$router.push({ name: "reminders.new" });
      }
    },
  },
};
</script>
